// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
// Originally based on the index.js in Gatsby's barebones starter, which is covered by the MIT
// license; see LICENSE for details.
import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";
import { BuyLink } from "../components/buy-link";
import cover from "../images/the-new-toys-cover.png";

const IntroSection = ({mostRecentPost: {title, slug}}) => <>
    <div className="most-recent-blog-post">
        <span className="star">★ </span>
        <span className="full-caption">Latest on the blog: <Link to={slug}>{title}</Link></span>
        <Link className="short-caption" to={slug}>Latest on the blog</Link>
        <span className="star"> ★</span>
    </div>
    <section id="home" className="home-page__home">
        <div className="wrapper--flex">

            <div className="flex-container--columns container--small home__left-content">
                <Link tabIndex="-1" to="where-to-buy" className="no-hover">
                    <img className="home__book-cover" src={cover} role="presentation" />
                </Link>
            </div>

            <div className="flex-container--columns container--large home__right-content"> 
            
                <div className="home__intro"> 
                    <div className="home__intro-header">
                        <Link tabIndex="-1" to="where-to-buy" className="no-hover home__inline-book-cover">
                            <img className="home__book-cover" src={cover} role="presentation" />
                        </Link>
                        <h1>Bring your JavaScript skills up to date with The New Toys.</h1>
                    </div>
                    <p style={{clear: "both"}}>
                        Explores the newest features of the world's most popular programming language
                        while also showing readers how to track what's coming next.
                        After setting the stage by covering who manages the process of improving
                        JavaScript, how new features get introduced, terminology, and a high-level
                        overview of new features, it details each new or updated item in depth, with
                        example uses, possible pitfalls, and recommendations for updating old habits
                        to new.
                    </p>
                    <p>
                        {"Explore the "}
                        <Link to="/toc" style={{color: "white"}}>
                            Table of Contents
                        </Link>
                        , or click the link below to grab your copy!
                    </p>
                </div>
        
                <div className="button-row"> 
                    <BuyLink target="" to="where-to-buy" >Buy Now</BuyLink>
                </div>
        
            </div>

        </div>
    
    </section> 
</>;

const FrontPage = ({data: {mostRecentPost: {edges: [{node: {frontmatter: {title}, fields: {slug}}}]}}}) => (
    <Layout>
        <PageInfo title="Home" />
        <IntroSection mostRecentPost={{title, slug}} />
        {/*<IntroSection />*/}
    </Layout>
);

export default FrontPage;

export const pageQuery = graphql`
    query {
        mostRecentPost: allMarkdownRemark(
            limit: 1
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    frontmatter {
                        title
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
